import React from 'react';
import { graphql } from 'gatsby';

import Hero404 from '../../components/organisms/Hero/Hero404';
import { getHref, getImage } from '../../utils/sanityTypes';
import { RichTextContentProps } from '../../components/molecules/RichText/types';
import { NotFoundPageProps } from './types';

const NotFound: React.VFC<NotFoundPageProps> = (props) => {
  const { data } = props;

  const { sanityContentNotFound } = data ?? {};
  const {
    subtitle,
    title,
    images,
    buttonLink,
    contentRichText,
    hasDarkBackground,
    hasTextBgOverlay,
  } = sanityContentNotFound ?? {};
  const { buttonLabel: label, link } = buttonLink ?? {};

  return (
    <Hero404
      data={{
        subtitle: subtitle ?? '',
        title: title ?? '',
        images: (images ?? []).map((currentImage) => {
          const { mobileImage, image, _key: key } = currentImage ?? {};

          return {
            key: key ?? '',
            image: getImage(image),
            mobileImage: getImage(mobileImage),
          };
        }),
        buttonLink: {
          label: label ?? '',
          link: {
            to: getHref(link),
          },
        },
        contentRichText: (contentRichText ?? undefined) as unknown as RichTextContentProps,
        hasDarkBackground: hasDarkBackground ?? false,
        hasTextBgOverlay: hasTextBgOverlay ?? false,
      }}
    />
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    sanityContentNotFound {
      title
      subtitle
      images {
        _key
        image {
          ...ImageFragment
        }
        mobileImage {
          ...ImageFragment
        }
      }
      contentRichText: _rawContentRichText(resolveReferences: { maxDepth: 10 })
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
      hasDarkBackground
      hasTextBgOverlay
    }

    sanityFooter(i18n_lang: { eq: "en" }) {
      ...FooterFragment
    }

    sanityHeader(i18n_lang: { eq: "en" }) {
      ...HeaderFragment
    }
  }
`;

export default NotFound;
