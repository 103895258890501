import React from 'react';

import { HeroBaseProps } from '../types';
import * as Styled from '../styles';

import Image from '../../../atoms/Image';
import Heading from '../../../molecules/Heading';

const Hero404: React.FC<HeroBaseProps> = ({ data }) => {
  const {
    images,
    title,
    subtitle,
    content,
    contentRichText,
    buttonLink,
    hasTextBgOverlay,
    hasDarkBackground,
  } = data;

  return (
    <Styled.Hero {...{ hasTextBgOverlay }}>
      <Styled.HeroMediaBox>
        {hasTextBgOverlay && <Styled.HeroOverlay />}
        <Styled.HeroImage hasMobilePicture={!!images[0]?.mobileImage?.image}>
          {images[0]?.image && <Image loading="eager" {...images[0].image} />}
        </Styled.HeroImage>
        {!!images[0]?.mobileImage?.image && (
          <Styled.HeroImageMobile>
            <Image loading="eager" {...images[0].mobileImage} />
          </Styled.HeroImageMobile>
        )}
      </Styled.HeroMediaBox>
      <Styled.HeroInner>
        <Styled.HeroContent>
          <Styled.HeroContentInner>
            <Heading
              {...{ title, subtitle, content, contentRichText, hasDarkBackground }}
              hasLargeTitle
            />
            {buttonLink?.link.to && (
              <Styled.HeroAction to={buttonLink.link.to}>{buttonLink.label}</Styled.HeroAction>
            )}
          </Styled.HeroContentInner>
        </Styled.HeroContent>
      </Styled.HeroInner>
    </Styled.Hero>
  );
};

export default Hero404;
